import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "고객 후기",
    paragraph: "요약",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <h2>서든어택</h2>
          <span>
            넥슨의 주력 FPS 게임이다. 본래 구 게임하이였던 넥슨GT에서
            제작했으나, 서든핵 넥슨GT와 넷게임즈가 합병하여 넥슨게임즈가
            출범함에 따라 이쪽에서 개발을 서든어택핵 담당하고 있다. 배급은
            넥슨에서 하는 온라인 FPS 게임. 게임 제목 자체는 '기습공격'이라는
            뜻이며 이 게임 때문에 국내에선 '서든'이라는 단어 자체가 이 게임을
            칭하는 용어나 다름없게 되었다. 2016년 7월엔 후속작인 서든어택 2가
            발표되었지만 게임 내외적으로 논란이 많아서 출시 2개월 만인 2016년
            9월 29일에 서비스가 종료되었다. 서든어택자판기 서든어택이 남긴
            기록들(2015년 8월) 2019년 9월 25일 정오를 서든자판기 기점으로
            서든어택의 마지막 해외 서버인 일본 서든어택이 서비스를 종료함에
            따라, 일본 넥슨에는 서든어택랜덤계정 더이상 FPS 게임이 존재하지
            않는다. 이제 전 세계에서 서든어택이 서비스 되는 중인 국가는 대한민국
            뿐이다. 일본 서든어택 서비스 종료 공지 아카이브 2021년 2월 4일을
            끝으로 서든어택의 네이버 채널링 서든핵자판기 서비스가 7년 만에
            종료를 선언했다. 2005년에 서비스를 시작한 게임답게 스토리 내의
            시간적 배경은 2010년~2012년으로 되어 있으며, 서든오토에임 게임
            서비스 초반에는 맵의 배경이나 서든 바디샷 유료 캐릭터들의 설정에
            해당 스토리를 반영하려는 노력을 보였지만 유저들 중에서 스토리에
            신경쓰는 사람은 거의 없었고 게임이 계속 서비스되면서 이 스토리와
            설정은 유저와 개발진 모두에게 잊혀서 더 이상 게임에 반영되지 않게
            되었다. 설정상 서든어택은 잔호크부대를 포함한 반군을 섬멸하기 위해
            투입된 부대명인데 연예인 캐릭터를 추가하기 시작한 전후 시점부터
            신캐릭터를 넣을 때 분명 서든어택(블루)팀과 적대적인 레드팀
            캐릭터임에도 아무렇지도 않게 의상에 서든어택이란 팀명과 로고를
            박아놓는 서든 유도탄 만행을 저지르곤 했다. 2022년 기준으로는 시간적
            배경인 2010년~2012년으로부터 이미 10~12년이 지났다
          </span>
          <h2>리그오브레전드 (롤)</h2>
          <span>
            라이엇 게임즈가 개발 및 서비스 중인 롤헬퍼 MOBA 장르의 게임. 게임
            명칭의 앞 철자들을 따서 LoL(롤), 영어권에서는 League 등의 약칭으로도
            불린다. 이전까지 있었던 MOBA(AOS) 게임들보다 진입 장벽을 낮추는
            것으로 높은 인기를 얻었고 현재는 롤핵 전 세계에서 많은 유저들을
            보유중인데 PC 게임 중 전 세계에서 많이 플레이하는 게임 중 하나이며
            2016년 기준 월 플레이어 수 롤계정 1억 명 이상을 달성했고, 2019년 8월
            기준 하루 전 세계 서버의 피크 시간 동시 접속자 수를 합치면 800만 명
            이상이다. 또한 전 세계 E스포츠 대회 중 가장 많은 시청자 수 기록을
            보유 중인 리그 오브 레전드 월드 챔피언십과 각 지역 리그 등 수많은
            e스포츠 대회가 많이 개최되는 중이다. 그리고 2018 자카르타·팔렘방
            아시안 게임에서 공식 시범 종목으로 채택되기도 했다. 기본적으로 빠른
            대전 게임(비공개 선택)은 유저 10명이 5 vs 5로 팀을 나눠서 진행된다.
            포지션 설정 없이 진행되기 때문에 원하는 라인에 가려면 순발력이 어느
            정도 필요하다. 포지션 여부에 상관없이 꼴픽을 하는 경우가 있어서
            투정글(!), 투탑처럼 기상천외한 일들이 일어나기도 하다. 물론 이는
            비매너 행위이니 삼가자.자랭을 돌리지 못하는 4인 큐의 정치질이 문제가
            되기도 한다. 북미 서버에는 일반 (교차 선택)이 존재하여 랭크 게임과
            같은 방식으로 진행이 가능하다.챔피언 보유수가 로테이션 포함 20개
            이상일 경우 해금 가능하다.
          </span>
          <h2>오버워치</h2>
          <span>
            미국의 블리자드 엔터테인먼트에서 제작한 팀 오버워치핵 기반
            멀티플레이 하이퍼 FPS 게임. 2016년 5월 24일에 PC, PS4, Xbox One으로
            전 세계에 발매되었고, 2022년 10월 4일 오전 1시 경 오버워치 2
            서비스를 출시하기 위한 패치와 옵치핵 점검을 위해 서비스가
            종료되었으며, 이후 10월 5일에 오버워치 2로 서비스가 이전되었다.
            2019년 10월 15일에 닌텐도 스위치 버전이 출시되었다. 콘솔 버전 중
            유일하게 한국어가 지원된다. 패키지 옵치에임봇 버전도 출시될
            예정이나, 게임 카드는 빠지고 DL 코드만 동봉된다고 한다. 단 아마존
            재팬 등에서 판매하는 옵치핵 디스코드 일본판은 게임 카드가 존재한다.
            용량은 기본 베이스인 12기가로, 추가 12기가 정도의 업데이트 용량을
            받아야 실행 가능하다. 추가로 스위치 온라인 3개월 이용권도 함께
            동봉된다. 블리자드에서 출시된 PC 게임들 중 최초로 게임패드를
            지원한다. 오버워치계정 게임 내 UI가 패드에도 최적화되어 있다 해도
            손색없을 정도이고, 진동까지 지원한다. 물론 찰진 손맛을 느끼려고 하면
            패드로 해도 손색이 옵치아이디 없지만 반응속도나 조작성 면에서는
            키보드+마우스가 훨씬 우세하다. 이러한 것들을 이유로 PC-콘솔 간
            크로스 플랫폼 지원이 되지 않는 듯 하다. 관련 유저 토론 그러다 2021년
            6월 23일부터 경쟁전을 옵치핵샵 제외한 모든 게임모드에서 크로스
            플랫폼 베타가 시작되었다. 자세한 내용은 해당 문서 참조.
          </span>
          <h2>배틀그라운드 (배그)</h2>
          <span>
            크래프톤의 자회사인 펍지 스튜디오의 MMO 슈팅 게임이다. 공식 명칭은
            PUBG: BATTLEGROUNDS, 배그핵 약칭 배틀그라운드이며, 더 줄여서
            배그라고 많이 부른다. 게임플레이 형식은 브렌던 그린(Brendan Greene,
            닉네임 PLAYERUNKNOWN)이 이전에 제작했던 배틀그라운드핵 ARMA 3의 모드
            'PLAYERUNKNOWN'S Battle Royale'을 기반으로 하여, 일개 게임의 모드에
            그치지 않고 스탠드얼론으로 배그핵판매샵 제작된 작품이다. 최대
            100명의 인원이 무기와 탈것을 활용해 벌이는 배틀로얄 게임으로, 방대한
            오픈 월드 맵에서 물품을 얻고 다양한 전략을 이용해 싸우면서 최후의
            1인 혹은 1팀을 가려내는 배그계정 방식이라고 할 수 있다. 스팀을 통해
            배그아이디 전세계 동시 출시를 하였으며, 300만명이 넘는 스팀역사상
            최다 동접자기록을 세우면서 본격적인 배틀로얄 게임 시대를 열었다는
            평가를 받고 있다. 비록 배그에임봇 왕좌를 포트나이트에 넘겨주긴
            했지만, 출시 이후 수많은 배틀로얄 게임들이 등장했으며 콜 오브 듀티
            등의 메이저 게임도 배틀로얄 모드를 도입시키는 등 게임계에 상당한
            파장을 불러일으켰다는 데에서 영향력이 배그계정구매사이트 큰
            게임이다. 국내시장도 예외가 아니라서 100주넘게 PC방을 점령했던 LoL을
            한동안 밀어냈고, 2017년 대한민국 배그 esp 게임대상을 차지했다! 현재
            경쟁작으로는 2017년 09월 27일에 나온 포트나이트 배틀로얄, 후발주자
            Apex 레전드, 콜 오브 듀티: 워존 퍼시픽 정도가 있다. 12.2차 패치로
            게임 이름이 PLAYERUNKNOWN'S BATTLEGROUNDS에서 배틀그라운드 핵 PUBG:
            BATTLEGROUNDS로 변경되었다. 본래 유료 게임이었으나, 2022년 1월 12일
            라이브 서버 점검 이후 부분 유료화로 전환됐다. 단, 무료 유저는 게임의
            일부 기능을 이용할 수 없으며, 랭크 모드인 경쟁전 플레이를 하거나,
            커스텀 매치 개설, 그리고 기타 혜택을 누리기 위해서는 플러스(Plus)
            계정으로 업그레이드해야 한다. 가격은 15,000원. 게임을 이미 유료로
            구매한 유저는 1월 12일부로 자동적으로 플러스 계정으로 전환되며, 특별
            보상을 수령하게 된다.
          </span>

          <h1>서든핵 배그핵 옵치핵 롤핵 핵블리샵</h1>
          <span>
            서든 핵모아 / 서든 핵 디스코드 / 서든핵무료배포 / 서든 핵 판매샵 /
            서든핵판매 / 서든 핵 판매 / 서든 핵 구매 / 서든핵구매 / 옵치핵구매 /
            옵치 핵 판매 / 옵치 핵 / 옵치핵 판매샵 / 배그 핵 / 배그 핵 사이트 /
            서든어택 핵 자판기 / 자판기 핵 사이트 / 너지몰 사이트 / 너지몰핵 /
            너지몰 정지 / 핵마트 / 라이언샵 / 배그 ESP 가격 / 배틀그라운드 핵
            구매 사이트 / 배그핵 가격 / 핵토리 / 토리샵 / 서든핵토리 / 핵 토리 /
            핵 모아 / 게임 핵 사이트 / 핵시티 / 언로드구매 / 우디르핵 / 우디르 /
            오버워치2 핵 / 옵치2핵 / 서든어택바디핵 / 서든랜계 / 서든우디르핵 /
            서든 바디핵 / 서든 에임핵 / 오버워치 에임핵 / 서든어택 핵 구매 /
            서든어택 핵 최저가 / 서든핵 최저가 / 서든최저가 / 최저가 핵
            판매사이트 / 블리넷 / 블리net / 서든랜계샵 / 서든 자판기 / 핵토리
            사이트 / 토리샵사이트 / 핵써바주소 / 서든핵 샵 / 서든 핵자판기 /
            서든 랭크전 계정 / 서든 랜덤계정 구매 / 서든 랜덤계정 판매 / 랜계샵
            / 치닷 / 롤 헬퍼 / 한섭 롤 헬퍼 / 리그오브레전드핵 /
            리그오브레전드헬퍼 / 롤 헬퍼 판매 사이트 / 핵시티 / 롤 헬퍼샵 / 롤
            헬퍼 사이트 / 롤 헬퍼 구매 / 롤헬퍼 한봇 / 롤 헬퍼 데마시아 / 롤
            헬퍼 디스코드 / 롤헬퍼 판매 / 롤헬퍼 구매 / 롤헬퍼 슬롯 / 한국서버
            롤 헬퍼 / 발로란트 핵 판매샵 / 발로란트 핵 사이트 / 발로란트 핵 구매
            / 발로란트 핵 디스코드 / 발로란트 핵 가격 / 발로 핵 구매가격 /
            배그핵 핵마트 / 배그핵 너지몰 / 배그 핵 무료 / 모배핵 / 모바일
            배그핵 / 배그핵 esp / 배그핵 가격표 / 핵토리샵 / 게임핵 / 서든 핵
            판매 사이트 / 서든 핵 구매 사이트 / 핵써바샵 / 월핵 / 관통핵 /
            오토에임 / 에임고정 / 바디샷 / 유도탄 / 패킷킬 / 서든핵공유 /
            방송용핵 / 랭커용핵 / 학살용핵 / 핵전문 너지몰 / 서든 인피핵 /
            인피코드 / 서든 인피니티 / 서든어택 캐릭인피 / 오버워치 핵 판매샵 /
            오버워치 핵 사이트 / 오버워치 핵 디스코드 / 오버워치 핵 샵 /
            오버워치 핵 가격 / 핵라인 / 배그 핵마트 / 서든 랜계 사이트 /
            서든계정 구매사이트 / 서든어택 계정 판매샵 / 서든랜계 구매사이트 /
            서든어택별계정 / 서든어택 랭계
          </span>
          {/* <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — 너무 좋아요~</p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">이름</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">서든핵</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — 친절해요~</p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">이름</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">배그핵</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — 굿굿</p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">이름</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">롤</a>
                  </span>
                </div>
              </div>
            </div>

          </div> */}
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
