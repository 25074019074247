import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "사이트 이용방법 및 설명",
    paragraph: "365일 24시간 연중무휴 친절상담 핵블리",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  1 사이트 바로가기를 클릭 후 사이트에 접속해주세요
                </div>
                <h3 className="mt-0 mb-12">
                  1 사이트 바로가기를 클릭 후 사이트에 접속해주세요
                </h3> */}
                <p className="p-99">
                  1 사이트 바로가기를 클릭 후 사이트에 접속해주세요
                  <br /> 2 회원가입 후 로그인을 해주세요 <br /> ( 가입시
                  추천인은 적지 않아도 됩니다 ) <br /> 3 상점에서 구매하실
                  제품의 소개와 가격확인을 해주세요 <br />4 사이트 상단의
                  충전방식을 선택해 충전해주세요 <br />5 제품 구매후 파일함에서
                  사용법과 파일다운을 해주세요
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-01.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  기능2
                </div>
                <h3 className="mt-0 mb-12">기능설명</h3> */}
                <p className="p-99">
                  서든어택 / 배틀그라운드 / 오버워치 / 발로란트 / 롤 / 랜덤계정
                  <br />
                  모두 다른샵과 비교불가한 뛰어난 보안성과 가격으로 판매중입니다
                  <br />
                  24시간 친절한 상담과 원격서비스로 높은 만족도를 보입니다
                  <br />
                  무엇보다 기존고객 유지를 가장 중요시하는 저희 핵블리는 <br />
                  신규고객 뿐만아니라 기존고객님들을 위해 각종 많은 이벤트와
                  <br />
                  서비스를 제공합니다 "차별화된 제품"과 "핵블리만의
                  고객서비스"는 <br /> 저희 기존고객들이 많이 찾아주시는
                  이유입니다
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-02.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  기능3
                </div>
                <h3 className="mt-0 mb-12">기능</h3> */}
                <p className="p-99">
                  서든핵 / 서든어택핵 / 오버워치핵 / 옵치핵 / 배그핵 /
                  배틀그라운드핵 / 배그핵판매샵 / 서든핵판매사이트 /
                  서든핵구매사이트 / 서든계정 / 서든랜덤계정 / 배그아이디 /
                  배그계정 / 배그에임봇 / 옵치에임봇 / 서든월핵 / 언로드 /
                  블리샵 / 핵블리 / 너지몰 / 핵판매샵 / 핵판매사이트 / 핵자판기
                  / 서든핵자판기 / 배그핵자판기 / 치트닷컴 / 발로란트핵 / 발로핵
                  / 롤헬퍼 / 롤핵 / 롤계정 / 배그 핵 판매사이트 / 서든핵
                  디스코드 / 배그핵 디스코드 / 옵치핵 디스코드 / 하이퍼플릭 /
                  크리티컬히트 / 에데니티 / 배그계정구매사이트 / 배그 esp /
                  슈퍼피플핵 / 슈피핵 / 핵써바 / 서든 핵 / 배그 핵/ 배틀그라운드
                  핵 / 서든어택 핵 / 오버워치 핵 / 옵치 핵 / 서든 계정 / 랜덤
                  계정 / 아담스 / 치트닷컴 / 서든 유도탄 / 서든 바디샷 /
                  서든오토에임 / 무반동핵 / 에임고정핵 / 핵 구매사이트 / 핵
                  판매사이트 / 핵샵 / 우디르핵 / 오버워치계정 / 옵치아이디 /옵치
                  계정 / 옵치핵샵
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-03.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
