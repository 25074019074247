import React, { useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [url, setUrl] = useState("");
  const [videoModalActive, setVideomodalactive] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "핵블리의 차별화된 제품",
    paragraph:
      "사이트에 접속하시면 아래에 있는 제품 이 외에 더 많은 제품들을 만나보실 수 있습니다",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a
                      data-video="https://streamable.com/e/uu3dlv"
                      href="#0"
                      aria-controls="video-modal"
                      onClick={(e) => {
                        setUrl("https://streamable.com/e/uu3dlv");
                        openModal(e);
                      }}
                    >
                      <Image
                        className="has-shadow"
                        src={"https://i.imgur.com/WW0Wvby.png"}
                        alt="롤비공개헬퍼"
                        width={896}
                        height={504}
                      />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">롤 비공개 헬퍼</h4>
                  <p className="m-0 text-sm">한국서버 6개월이상 노정지</p>
                  <p className="m-0 text-sm2">
                    [ 자세한 제품소개 및 가격은 구매사이트 참조 ]
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a
                      data-video="https://streamable.com/e/avje5f"
                      href="#0"
                      aria-controls="video-modal"
                      onClick={(e) => {
                        setUrl("https://streamable.com/e/avje5f");
                        openModal(e);
                      }}
                    >
                      <Image
                        className="thumbnail"
                        src={"https://i.imgur.com/SqVO8jO.jpg"}
                        alt="서든바이퍼"
                        width={896}
                        height={504}
                      />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">서든 [ 바이퍼 ]</h4>
                  <p className="m-0 text-sm">정지율0% 본캐강력추천 제품</p>
                  <p className="m-0 text-sm2">
                    [ 자세한 제품소개 및 가격은 구매사이트 참조 ]
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a
                      data-video="https://streamable.com/e/ogiph"
                      href="#0"
                      aria-controls="video-modal"
                      onClick={(e) => {
                        setUrl("https://streamable.com/e/ogiph");
                        openModal(e);
                      }}
                    >
                      <Image
                        className="thumbnail"
                        src={"https://i.imgur.com/Y7JJ3k4.png"}
                        alt="옵치하이퍼플릭"
                        width={896}
                        height={504}
                      />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">옵치 [ 하이퍼플릭 ]</h4>
                  <p className="m-0 text-sm">
                    오버워치 제품중 보안및성능이 가장 뛰어난 제품
                  </p>
                  <p className="m-0 text-sm2">
                    [ 자세한 제품소개 및 가격은 구매사이트 참조 ]
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a
                      data-video="https://player.vimeo.com/video/735397818"
                      href="#0"
                      aria-controls="video-modal"
                      onClick={(e) => {
                        setUrl("https://player.vimeo.com/video/735397818");
                        openModal(e);
                      }}
                    >
                      <Image
                        className="thumbnail"
                        src={"https://i.imgur.com/SpO0HHY.jpg"}
                        alt="배그치우"
                        width={896}
                        height={504}
                      />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">배그 [ 치우 ]</h4>
                  <p className="m-0 text-sm">
                    에임봇,ESP 모든면에서 다른제품보다 뛰어난 제품입니다{" "}
                  </p>
                  <p className="m-0 text-sm2">
                    [ 자세한 제품소개 및 가격은 구매사이트 참조 ]
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a
                      data-video="https://streamable.com/dmz8nx"
                      href="#0"
                      aria-controls="video-modal"
                      onClick={(e) => {
                        setUrl("https://streamable.com/dmz8nx");
                        openModal(e);
                      }}
                    >
                      <Image
                        className="thumbnail"
                        src={"https://i.imgur.com/ArIMPZM.jpg"}
                        alt="배그AI"
                        width={896}
                        height={504}
                      />
                    </a>
                  </div>
                </div>

                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">배그 [ AI ]</h4>
                  <p className="m-0 text-sm">
                    성능 역대급 깔끔한 작동 한국어지원 제품
                  </p>
                  <p className="m-0 text-sm2">
                    [ 자세한 제품소개 및 가격은 구매사이트 참조 ]
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a
                      data-video="https://streamable.com/e11cqb"
                      href="#0"
                      aria-controls="video-modal"
                      onClick={(e) => {
                        setUrl("https://streamable.com/e11cqb");
                        openModal(e);
                      }}
                    >
                      <Image
                        className="thumbnail"
                        src={"https://i.imgur.com/lDA4gwE.png"}
                        alt="배그오레오"
                        width={896}
                        height={504}
                      />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">배그 [ 오레오 ]</h4>
                  <p className="m-0 text-sm">
                    초보자 입문용으로 좋은 제품입니다
                  </p>
                  <p className="m-0 text-sm2">
                    [ 자세한 제품소개 및 가격은 구매사이트 참조 ]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="video-modal"
        show={videoModalActive}
        handleClose={closeModal}
        video={url}
        videoTag="iframe"
      />
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
